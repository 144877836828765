<script>
import utilsMixin from '@shared/mixins/utils';
import APIUser from '@app/services/API/User';

export default {
  name: 'AffiliationRevenus',
  mixins: [utilsMixin],
  head: {
    title: 'Mes revenus - Affiliation',
  },
  messages: {
    custom: 'Personnalisé',
    today: 'Aujourd\'hui',
    thisWeek: 'Cette semaine',
    last7Days: '7 derniers jours',
    thisMonth: 'Ce mois-ci',
    last30Days: '30 derniers jours',
    thisYear: 'Cette année',
  },
  data() {
    return {
      isFetching: false,
      filters: {
        dates: [],
        selected: '',
      },
      prefilledDates: {
        today: [new Date(), new Date()],
        thisWeek: [
          this.$moment().startOf('week').toDate(),
          this.$moment().endOf('week').toDate(),
        ],
        last7Days: [
          this.$moment().subtract(7, 'days').toDate(),
          new Date(),
        ],
        thisMonth: [
          this.$moment().startOf('month').toDate(),
          this.$moment().endOf('month').toDate(),
        ],
        last30Days: [
          this.$moment().subtract(30, 'days').toDate(),
          new Date(),
        ],
        thisYear: [
          this.$moment().startOf('year').toDate(),
          this.$moment().endOf('year').toDate(),
        ],
        custom: '',
      },
      maxDate: new Date(),
      commissions: {
        data: [],
        links: {},
        meta: {},
      },
      commissionsStats: {},
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    getPage(page = 1) {
      this.isFetching = true;

      if (this.filters.selected !== this.filters.dates) {
        this.filters.selected = '';
      }

      const params = {
        page,
        begin: this.filters.dates[0],
        end: this.filters.dates[1],
      };
      params.begin && (params.begin = this.$moment(params.begin).format('YYYY-MM-DD'));
      params.end && (params.end = this.$moment(params.end).format('YYYY-MM-DD'));

      Promise.all([
        APIUser.getGodchildrenCommissions(params)
          .then((data) => (this.commissions = data)),
        APIUser.getGodchildrenCommissionsStats(params)
          .then(({ data }) => (this.commissionsStats = data)),
      ])
        .finally(() => (this.isFetching = false));
    },
    onSelected() {
      if (this.filters.selected) {
        this.filters.dates = this.filters.selected;
        this.getPage();
      }
    },
  },
  created() {
    this.filters.selected = this.prefilledDates.thisMonth;
    this.filters.dates = this.filters.selected;
    this.getPage();
  },
};
</script>

<template>
  <section>
    <div class="level is-justify-content-flex-end">
      <form class="level-right is-align-items-flex-end" @submit.prevent="getPage()">
        <div class="level-item">
          <b-select v-model="filters.selected" @input="onSelected">
            <template v-for="(v, k) in prefilledDates">
              <option v-if="v != '' || (v == '' && filters.selected == '')" :key="k" :value="v">
                {{ $options.messages[k] }}
              </option>
            </template>
          </b-select>
        </div>
        <div class="level-item">
          <b-field>
            <b-datepicker
              v-model="filters.dates"
              position="is-top-left"
              :max-date="maxDate"
              range
              @range-start="filters.selected = ''"
              @input="getPage()"
            />
          </b-field>
        </div>
      </form>
    </div>
    <div class="level has-text-centered">
      <div class="level-item px-3">
        <div class="box w-full content">
          <h3 class="is-size-7 has-text-weight-normal">
            <b-tooltip label="Nombre de clics sur vos liens d'affiliation">
              Nombre de clics
            </b-tooltip>
          </h3>
          <p class="title">
            <template v-if="isFetching">...</template>
            <template v-else>{{ authUser.god_father_visits }}</template>
          </p>
        </div>
      </div>
      <div class="level-item px-3">
        <div class="box w-full content">
          <h3 class="is-size-7 has-text-weight-normal">
            <b-tooltip label="Nombre de transactions vous ayant apporté des commissions">
              Nombre de transactions
            </b-tooltip>
          </h3>
          <p class="title">
            <template v-if="isFetching">...</template>
            <template v-else>{{ commissions.meta.total }}</template>
          </p>
        </div>
      </div>
      <div class="level-item px-3">
        <div class="box w-full content">
          <h3 class="is-size-7 has-text-weight-normal">
            <b-tooltip label="Revenus générés par vos affiliés au profit de Teachizy">
              Revenus générés
            </b-tooltip>
          </h3>
          <p class="title">
            <template v-if="isFetching">...</template>
            <template v-else>{{ commissionsStats.total_amount | formatPrice }}</template>
          </p>
        </div>
      </div>
      <div class="level-item px-3">
        <div class="box w-full content">
          <h3 class="is-size-7 has-text-weight-normal">
            <b-tooltip
              label="Le montant de votre commission exigible à Teachizy"
              position="is-left"
            >
              Votre commission
            </b-tooltip>
          </h3>
          <p class="title">
            <template v-if="isFetching">...</template>
            <template v-else>{{ commissionsStats.total_commissions | formatPrice }}</template>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <b-table
        :data="commissions.data"
        :loading="isFetching"
        :total="commissions.meta.total || 0"
        :per-page="commissions.meta.per_page || 1"
        paginated
        backend-pagination
        @page-change="getPage"
      >
        <b-table-column cell-class="py-5" v-slot="{ row }" field="store_name" label="Formateur">
          {{ row.store_name }} <br>
          <a :href="`//${row.store_subdomain}.${$env.rootHost}`" target="_blank">
            {{ row.store_subdomain }}.{{ $env.rootHost }}
          </a>
        </b-table-column>
        <b-table-column cell-class="py-5 mw-200" v-slot="{ row }" field="plan" label="Pack">
          {{ row.plan === 'PERCENT' ? 'DÉCOUVERTE' : 'PRO' }}
        </b-table-column>
        <b-table-column cell-class="py-5 mw-200" v-slot="{ row }" field="paid" label="Montant" numeric>
          {{ row.paid | formatPrice }}
        </b-table-column>
        <b-table-column cell-class="py-5 is-size-8" v-slot="{ row }" field="created_at" label="Date" numeric>
          <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
            {{ row.created_at | momentFromUTC | moment('from') }}
          </b-tooltip>
        </b-table-column>
        <b-table-column cell-class="py-5 mw-200" v-slot="{ row }" field="commission" label="Votre commission" numeric>
          {{ row.commission | formatPrice }}
        </b-table-column>

        <template #empty v-if="!isFetching">
          Vous n'avez pas généré de revenus sur cette période.
        </template>
      </b-table>
    </div>
  </section>
</template>
